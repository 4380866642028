.video{
    padding: 50px 10%;
    display: flex;
    width: 100%;
}
.video iframe{
    height: 50vh;
    width: 100%;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
@media (max-width: 900px) {
    .video{
        padding: 50px 6%;
    }
    .video iframe{
        height: 40vh;
    }
}