header {
    width: 100%;
    z-index: 10;
    transition: all 0.2s linear;
    position: absolute;
    z-index: 100;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.037);
    
    position: fixed;
}
nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    width: 100%;
    transition: all 0.2s linear;
    padding: 10px 10% ;
    background-color: transparent;
   

}
.scroll{
    background-color: var(--color1);
}

.deFLexNavs{
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    gap: 3rem;
}
.deFLexNavs a{
    color: var(--blanco);
}

.nav_items.open {
    transform: translateX(0) !important;
  
}


.nav_toggle{
    display: flex ;
    flex-direction: column;       
    cursor: pointer;
    justify-content: right;
    text-align: right;
    display: none;
   

}
.nav_toggle span{
    width: 25px;
    height: 3px;
    background: var(--blanco);
    margin-bottom: 5px;
    border-radius: 20px;
    transform-origin: 3px 0px;
    transition: all 0.2s linear;
    display: flex;
    justify-content: right;
    text-align: right;
  
}
.nav_toggle span:nth-child(1) {
    width: 23px;
}

.nav_toggle span:nth-child(2) {
    width: 23px; 
}

.nav_toggle span:nth-child(3) {
    width: 23px; 
}



.logo img{
    width: 120px;
}



.enlaces{
    display: flex;
  
    gap: 1rem;
 
}

.search-icon{
    color: var(--blanco);
    font-size: 20px;
}
.enlaces a{
    color: var(--blanco);
    text-decoration: none;
    display: flex;
    padding: 10px;
    border-radius: 7px;
    align-items: center;
    font-size: 15px;
   
}









.modalNav-content {
    width: 100%;
    padding: 20px;
    display: flex;
    gap: 2rem;
    flex-direction: column;
}
.modalNav-content .logo{
    border-bottom: 1px solid var(--gris2);
    padding-bottom: 20px;
    align-items: center;
    text-align: center;
}
.modalNav {
    top: 0;
    background-color: var(--color1);
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 0px 10px 10px 0px;
    border: none;

}


.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent; 
    z-index: 999; 
    display: flex;
    flex-direction: column;
    padding-top: 4rem;
}
.deNone_aFlex{
    display: none;
}
.ubi{
    color: var(--text-color);
    font-size: 20px;
}
.enlaces2 {
    display: none;
  }
@media (max-width: 900px) {
    .deNoneNav{
        display: none;
    }
    .deFLexNavs{
        display: flex;
        gap: 2rem;
    }
    
 .deNone{
    display: none;
 }
  
    
    
    nav{
   
        padding: 10px 7%;
        background-color: var(--color1);
    }
 
    .enlaces2 {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding: 15px 6%;
      }
      .enlaces2 a{
      color: var(--blanco);
      }



 
  .logo img{
    width:100px;

}

 
    .enlaces{
    display: none;
    }
    .nav_toggle{
       
        display: flex;

       
    
    }
}


