.HeroContain{
 
   height: 90vh;
}
.HeroContain img{

   width: 100%;
   height: 100%;
   object-fit: cover;
}

@media (max-width: 900px) {
   .HeroContain{
      height: 13rem;

     
   }
   .HeroContain img{

      object-fit: contain;
      height: 19rem;
   }
}