.TitleSection2{
    color: var(--text-color);
    font-size: 20px;
    text-align: center;
    padding: 50px 10%;
    font-weight: 500;
}
.TitleSection2 h2{
    color: var(--text-color2);
    font-size: 30px;
    text-align: center;
  
    font-weight: 500;
}
.TitleSection2 h1{
    color: var(--text-color);
    font-size: 40px;
    text-align: center;
}
@media (max-width: 900px){
    .TitleSection2 h1{
        font-size: 30px;
    }
    .TitleSection2 h2{
        font-size: 20px;
    }
    .TitleSection2{
        padding: 50px 7%;
    }
}