.Numeros{
    padding: 50px 10%;
    background-color: var(--gris2);
}
.NumerosCards{
    display: flex;
    gap: 3rem;
    justify-content: space-between;
}
.NumeroCard{
    background-color: var(--blanco);
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    place-content: center;
    padding: 20px;
    min-height: 10rem;
    border-radius: 8px;
   display: flex;
   flex-direction: column;
   gap: 1rem;
}
.NumeroCard h2{
    font-size: 30px;
    color: var(--text-color2);
}
.NumeroCard p{
    font-size: 16px;
    color: var(--text-color2);
}
@media (max-width: 900px) {
    .NumerosCards{
       flex-direction: column;
    
    }
    .Numeros{
        padding: 50px 6%;
    }
}