.Nosotros{
    display: flex;
    padding: 50px 10%;
    gap: 2rem;
    align-items: center;
}
.Nosotros img{
    border-radius: 10px;
    height: 70vh;
    width: 100%;
    object-fit: cover;
   
}
.NosotrosText{
    display: flex;
    gap: 1rem;
    flex-direction: column;
}
.NosotrosText h2{
    font-size: 30px;
    color: var(--text-color);
}
.NosotrosText p{
    font-size: 16px;
    color: var(--text-color2);
    line-height: 1.5; 
}

@media (max-width: 900px) {
    .Nosotros{
       flex-direction: column;
       padding: 10px 7%;
    }
    .Nosotros img{
        height: 30vh;
}
.NosotrosText h2{
    font-size: 20px;
}
}