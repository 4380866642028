.FooterContain{
    padding: 20px 10%;
    background-color: var(--color2);
    display: flex;
   flex-direction: column;
    justify-content: space-between;
    gap: 3rem;
}
.FooterContain p{
  text-align: center;
}
.FooterContain a img{
    width: 100px;
}
.Footer{
    display: flex;
    justify-content: space-between;
    gap: 3rem;
}

.footerText{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.footerText h3{
    color: var(--color3);
    background-color: var(--color4);
    padding: 6px;
    border-radius: 7px;
    
}
.email{
    color: var(--color3)!important;
}
.footerText h4{
    color: var(--blanco);
    padding: 6px;
    border-radius: 7px;
    
}
.footerText h2{
    color: var(--blanco);
    font-weight: 500;
    margin-bottom: 10px;
}
.footerText a{

    color: var(--blanco);
}
.socials{
    display: flex;
    gap: 2rem;
}
.bgFooter{
    min-height: 15rem;
    width: 30rem;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-self: center;
    flex-direction: column;
    padding: 20px;
    gap: 1rem;

}
.bgFooter h2{
color: var(--blanco);
font-size: 30px;
width: 70%;
}
.bgFooter span{
    color: var(--blanco);
    font-size: 17px;
    }

    .footerText span{
        color: var(--blanco);
    }


.footerText span{
    width: 70%;
}

.suscribir{
    background-color: #ffffff15;
    border: none;
    border-radius: 10rem;
    padding: 10px;
    width: 100%;
   
}
.suscribir button{
background-color: var(--color1);
color: var(--blanco);
border-radius: 10rem;
    padding: 10px 20px;
    border: none;
}
.suscribir input{
    background-color:transparent;
    border-radius: 10rem;
        padding: 10px 20px;
        border: none;
    }

    .FooterContain p {
        color: var(--blanco);
    }
    .FooterContain span {
        color: var(--color3);
    }
@media (max-width: 900px){
    .FooterContain{
    margin: 0;
    padding: 30px 4%;
    flex-direction: column;

}
.Footer{
    flex-direction: column;
}
.bgFooter{
    width: 100%;
}
}