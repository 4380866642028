.Buton{

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 80px;
}
.Buton a{
    padding: 20px 30px;
    background-color: var(--color0);
    color: var(--blanco);
    border-radius: 10px;
    font-size: 20px;
}
@media (max-width: 900px) {
    .Buton{
        padding: 50px 7% 20px;
    }
    .Buton a{
        padding: 20px 20px;
        font-size: 17px;
    }
}