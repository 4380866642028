.TitelSection{
    color: var(--text-color);
    font-size: 40px;
    text-align: center;
    padding: 50px 5%;
    font-weight: 500;
}
@media (max-width: 900px){
    .TitelSection{
        font-size: 30px;
    }
}