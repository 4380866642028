#swiper_container_galery{
  
    display: flex;
  
   width: 100%;
   height: 100%;
}

#SwiperSlide-Galery{
    width: 30vh;
    height: 30vh;
    margin: 1rem;
    border-radius: 10px;
    overflow: hidden;
    background-color: var(--blanco);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.188);
}

#SwiperSlide-Galery img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.galeryContain{
    padding: 50px 10%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
   
}

@media (max-width: 900px){
    .galeryContain{
        padding: 20px 0%;
    }
    #swiper_container_galery{
        padding-left: 3%;
    }
    
#SwiperSlide-Galery{
    width: 6rem;
    height: 6rem;
    border-radius: 10px;
    overflow: hidden;
   
}
}